import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import backgroundVideo from '../images/video.mp4';

const Hero = () => {
    return (
        <>
            <div className="hero relative overflow-hidden w-full h-screen" id="hero">

                {/* Background Video */}
                <video
                    autoPlay
                    loop
                    muted
                    className="absolute top-0 left-0 w-full h-full object-cover -z-10"
                >
                    <source src={backgroundVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                {/* Light Overlay Effect */}
                <div className="absolute top-0 left-0 w-full h-full bg-white opacity-20 -z-10"></div>

                {/* Navigation Bar */}
                <div className="relative z-10">
                    <NavBar />
                </div>

                {/* Hero Content */}
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-8 sm:p-12 md:p-16 h-auto lg:h-5/6 relative">
                    <div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        {/* Left Content */}
                        <div className="lg:w-1/2 flex flex-col justify-center items-center lg:items-start">
                            <h1 className="mb-5 text-3xl md:text-4xl lg:text-5xl font-bold" style={{ color: '#ff8c00' }}>
                                Vollmoon Technologies for your unique business needs
                            </h1>
                            <div className="text-lg md:text-xl font-semibold tracking-tight mb-5 text-[#1E3A84]">
                                {/* We are a team of highly motivated and skilled developers dedicated to delivering only the best software. */}
                                We provide effective and simple solutions across the whole health system to significantly improve quality of care and help lower costs.
                            </div>

                            {/* Button */}
                            <div className="mb-4 md:mb-8">
                                <Link
                                    to="/contact"
                                    className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-6 py-3 text-sm sm:text-lg shadow-xl rounded-2xl"
                                >
                                    Learn more
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};

export default Hero;
