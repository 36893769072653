import React, { useState, useEffect } from "react";
import "./Healthcare.css";
import AOS from "aos";
import "aos/dist/aos.css";
import zigZagImg from "../images/Healthcare.webp";
import emrImg from "../images/emr.webp";
import medicalImg from "../images/medical.webp";
import teleImg from "../images/teleImg.webp";
import proImg from "../images/proImg.webp";
import remote from "../images/Remote.webp";
import mhealth from "../images/mhealth.webp";
import hl7 from "../images/hl7.webp";
import iot from "../images/iot.webp";

function Healthcare() {
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const handleMouseEnter = (index) => {
    setOpenIndex(index);
  };

  const handleMouseLeave = () => {
    setOpenIndex(null);
  };

  const details = [
    {
      title: "Healthcare Organization Management Software",
      description:
        "Software for managing healthcare organizations, streamlining operations, and ensuring efficient service delivery.",
    },
    {
      title: "Care Management Software",
      description:
        "Tools for tracking patient care plans, enhancing care coordination, and improving patient outcomes.",
    },
    {
      title: "Patient-Centered Software",
      description:
        "Solutions designed to enhance patient engagement, satisfaction, and personalized healthcare experiences.",
    },
    {
      title: "Medical Imaging and Lab Software",
      description:
        "Software for medical imaging, laboratory management, and data analysis to support diagnostics and patient care.",
    },
    {
      title: "Medical Device Software and SaMD",
      description:
        "Software for medical devices, including Software as a Medical Device (SaMD), ensuring compliance and performance.",
    },
    {
      title: "User Software",
      description:
        "User-centric applications tailored to provide seamless interaction and access to healthcare services.",
    },
    {
      title: "Management Software",
      description:
        "Management tools for overseeing healthcare operations, resources, and compliance.",
    },
    {
      title: "End-Customer Software",
      description:
        "Software solutions for end customers, enhancing their access to services and information in the healthcare sector.",
    },
  ];

  return (
    <div className="healthcare-container">
      <h1 className="main-heading">Healthcare</h1>
      <h2 className="sub-heading">Healthcare Software Development Solutions</h2>
      <p className="description">
        Transit to digital healthcare and achieve better outcomes with our
        digitally smart healthcare software development services. We utilize the
        web, mobile, and medical devices to enable the next-gen quality of
        healthcare software solutions.
      </p>

      <div className="solutions-container">
        {details.map((item, index) => (
          <div
            className="solution-column"
            key={index}
            data-aos="fade-up"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <ul>
              <li>{item.title}</li>
              {openIndex === index && <p className="details">{item.description}</p>}
            </ul>
          </div>
        ))}
      </div>

      <div className="zigzag-layout" data-aos="fade-up">
        <img src={zigZagImg} alt="Zigzag Healthcare Solutions" className="zigzag-image" />
        <div className="zigzag-content">
          <h2 className="sub-heading">Healthcare Software Development Services</h2>
          <ul>
            <li>Design and development of customized software solutions to enhance patient care.</li>
            <li>End-to-end healthcare software services for organizations and startups.</li>
            <li>Develop solutions that streamline patient records, appointments, and billing to improve operational efficiency and enhance patient care.</li>
            <li>Build secure, compliant platforms for managing patient data efficiently, supporting clinical decisions, and meeting healthcare regulations.</li>
          </ul>
        </div>
      </div>

      <div className="zigzag-layout reverse" data-aos="fade-up">
        <img src={emrImg} alt="EMR and EHR Software Development" className="zigzag-image" />
        <div className="zigzag-content">
          <h2 className="sub-heading">EMR and EHR Software Development</h2>
          <ul>
            <li>Custom Electronic Health Record (EHR) and Electronic Medical Record (EMR) software development.</li>
            <li>Enhancing patient experience while booking appointments and monitoring treatments.</li>
            <li>Develop tailored EMR and EHR systems for efficient storage, access, and management of patient records, enhancing the quality of care and clinical workflows.</li>
            <li>Ensure systems integrate smoothly with existing healthcare infrastructure while meeting industry regulations (e.g., HIPAA) for data security and compliance.</li>
          </ul>
        </div>
      </div>

      <div className="zigzag-layout" data-aos="fade-up">
        <img src={medicalImg} alt="Medical Software Development" className="zigzag-image" />
        <div className="zigzag-content">
          <h2 className="sub-heading">Medical Software Development</h2>
          <ul>
            <li>Software as a Medical Device (SaMD) for better monitoring of equipment.</li>
            <li>Management of internal processes and healthcare supply chain.</li>
            <li>Develop specialized software for medical devices, enhancing monitoring, diagnostics, and patient care while ensuring regulatory compliance.</li>
            <li>Create solutions that automate internal processes like asset management, insurance claims, and supply chain management to improve efficiency and reduce costs.</li>
          </ul>
        </div>
      </div>

      <div className="zigzag-layout reverse" data-aos="fade-up">
        <img src={teleImg} alt="Telemedicine software Development" className="zigzag-image" />
        <div className="zigzag-content">
          <h2 className="sub-heading">Telemedicine Software Development</h2>
          <ul>
            <li>As one of the best healthcare IT solutions providers, we develop feature-rich telemedicine software for your hospital or clinic that facilitates contactless treatment and allows physicians to consult, diagnose, and treat patients via video calling or chat.</li>
            <li>Creating an intuitive and accessible interface that allows patients and healthcare providers to easily connect, schedule, and conduct virtual consultations.</li>
            <li>Ensuring compliance with healthcare regulations like HIPAA by implementing robust security measures to protect patient information during virtual interactions.</li>
          </ul>
        </div>
      </div>

      <div className="zigzag-layout" data-aos="fade-up">
        <img src={proImg} alt="Healthcare Software Product" className="zigzag-image" />
        <div className="zigzag-content">
          <h2 className="sub-heading">Healthcare Software Product</h2>
          <ul>
            <li>Utilizing healthcare software product development services, we help you build software products that are cloud-based SaaS models, MVPs, and custom, adhering to regulations such as HIPAA and maintaining compliance with legal requirements.</li>
            <li>Healthcare software helps doctors and hospitals track patient history, treatment plans, and medications more efficiently, leading to better care and fewer errors.</li>
            <li>It simplifies tasks like appointment scheduling and billing, making it easier for patients to book appointments and for providers to manage payments and insurance claims.</li>
            <li>Healthcare software can analyze patient data to identify trends, predict health outcomes, and support medical decisions, helping providers deliver more personalized and proactive care.</li>
          </ul>
        </div>
      </div>

      <div className="zigzag-layout reverse" data-aos="fade-up">
        <img src={remote} alt="Remote monitoring software" className="zigzag-image" />
        <div className="zigzag-content">
          <h2 className="sub-heading">Remote Monitoring Software</h2>
          <ul>
            <li>From wearables to IoT-driven devices, our healthcare software experts build Remote Patient Monitoring (RPM) software that allows healthcare professionals to monitor patients’ health remotely and provide timely interventions.</li>
            <li>Remote patient monitoring software allows healthcare providers to monitor patients' vital signs, such as heart rate, blood pressure, and glucose levels, in real-time from a distance, enabling quicker responses to any concerning changes.</li>
            <li>By tracking patient health remotely, this software helps reduce the need for frequent in-person checkups, making it more convenient for patients, especially those with chronic conditions, while also freeing up healthcare resources.</li>
          </ul>
        </div>
      </div>

      <div className="zigzag-layout" data-aos="fade-up">
        <img src={mhealth} alt="mHealth App Development" className="zigzag-image" />
        <div className="zigzag-content">
          <h2 className="sub-heading">mHealth App Development</h2>
          <ul>
            <li>As a renowned healthcare mobile app development company, we develop smart mHealth applications for healthcare organizations that allow them to automate processes, streamline workflow, manage healthcare records, and deliver better patient care.</li>
            <li>mHealth apps enable users to track various health metrics—such as steps, calories, sleep patterns, and heart rate—while offering personalized recommendations to improve wellness and reach health goals.</li>
            <li>These apps allow users to communicate directly with healthcare providers through chat, video calls, or appointment scheduling, making healthcare more accessible and immediate for users.</li>
          </ul>
        </div>
      </div>

      <div className="zigzag-layout reverse" data-aos="fade-up">
        <img src={hl7} alt="HL7/FHIR Integration and Software" className="zigzag-image" />
        <div className="zigzag-content">
          <h2 className="sub-heading">HL7/FHIR Integration and Software</h2>
          <ul>
            <li>Our healthcare app development and HL7/FHIR integration help hospitals and surgery to aggregate data from various systems to create a data repository for better patient care, value-based care, quality improvement, research, and well-informed decision-making.</li>
            <li>HL7/FHIR integration allows different healthcare systems to share patient data efficiently, ensuring that information such as medical history, lab results, and treatment plans are readily accessible across platforms, improving continuity of care.</li>
            <li>By using FHIR (Fast Healthcare Interoperability Resources) standards, healthcare software can integrate with other systems more easily, enabling healthcare providers to access a comprehensive view of patient data from various sources without manual data entry.</li>
          </ul>
        </div>
      </div>

      <div className="zigzag-layout" data-aos="fade-up">
        <img src={iot} alt="IoT Healthcare Software" className="zigzag-image" />
        <div className="zigzag-content">
          <h2 className="sub-heading">IoT Healthcare Software</h2>
          <ul>
            <li>We develop IoT-based healthcare software or Internet of Medical Things (IoMT) applications for the healthcare industry that stores all patients’ data through smart sensors and monitor that data to gain valuable insights for medical professionals and doctors.</li>
            <li>IoT healthcare software connects to wearable devices and sensors that track patients' vital signs and health data 24/7, allowing healthcare providers to monitor conditions like heart rate, blood pressure, and activity levels in real-time.</li>
            <li>This software can send automated alerts to both patients and providers when it detects irregular health patterns, helping to prevent emergencies by enabling early intervention and timely medical responses.</li>
          </ul>
        </div>
      </div>

      
    </div>
  );
}

export default Healthcare;
