import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';

// Import components
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import Healthcare from './components/Healthcare';
import ScrollToTop from './components/ScrollToTop';
import { useDocTitle } from './components/CustomHook';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Vollmoon Technologies");

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/healthcare" element={<Healthcare />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
